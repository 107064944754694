import React from 'react'
import { PageProps } from 'gatsby'
import {
  Head,
  Hero,
  Breadcrumb,
  ContactForms,
  Location,
  Footer,
} from '~/components'
import Banner from '~/assets/img/Contato.jpg'

const Contact = ({ location }: PageProps) => (
  <>
    <Head
      location={location}
      title={'Contato - ' + process.env.GATSBY_SITE_NAME}
    />
    <Hero title="Contato" image={Banner} />
    <Breadcrumb />
    <ContactForms location={location} />
    <Location />
    <Footer />
  </>
)

export default Contact
